import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { checkWebPSupport } from "supports-webp-sync";
import { useIsMobile } from "../../hooks";

import darkMoss from "assets/images/landingAir/background-tab/dark-moss.jpg";
import darkMossWebp from "assets/images/landingAir/background-tab/dark-moss.jpg?as=webp";
import darkMossSm from "assets/images/landingAir/background-tab/dark-moss-sm.jpg";
import darkMossSmWebp from "assets/images/landingAir/background-tab/dark-moss-sm.jpg?as=webp";
import smokyGray from "assets/images/landingAir/background-tab/smoky-gray.jpg";
import smokyGrayWebp from "assets/images/landingAir/background-tab/smoky-gray.jpg?as=webp";
import smokyGraySm from "assets/images/landingAir/background-tab/smoky-gray-sm.jpg";
import smokyGraySmWebp from "assets/images/landingAir/background-tab/smoky-gray-sm.jpg?as=webp";
import dustyTaupe from "assets/images/landingAir/background-tab/dusty-taupe.jpg";
import dustyTaupeWebp from "assets/images/landingAir/background-tab/dusty-taupe.jpg?as=webp";
import dustyTaupeSm from "assets/images/landingAir/background-tab/dusty-taupe-sm.jpg";
import dustyTaupeSmWebp from "assets/images/landingAir/background-tab/dusty-taupe-sm.jpg?as=webp";
import twilightBlue from "assets/images/landingAir/background-tab/twilight-blue.jpg";
import twilightBlueWebp from "assets/images/landingAir/background-tab/twilight-blue.jpg?as=webp";
import twilightBlueSm from "assets/images/landingAir/background-tab/twilight-blue-sm.jpg";
import twilightBlueSmWebp from "assets/images/landingAir/background-tab/twilight-blue-sm.jpg?as=webp";
import purelyWhite from "assets/images/landingAir/background-tab/purely-white.jpg";
import purelyWhiteWebp from "assets/images/landingAir/background-tab/purely-white.jpg?as=webp";
import purelyWhiteSm from "assets/images/landingAir/background-tab/purely-white-sm.jpg";
import purelyWhiteSmWebp from "assets/images/landingAir/background-tab/purely-white-sm.jpg?as=webp";

const tabData = [
  {
    title: () => (
      <>
        Purely <br />
        White
      </>
    ),
    color: "#ffffff",
    isWhite: true,
    image: purelyWhite,
    imageWebp: purelyWhiteWebp,
    imageSm: purelyWhiteSm,
    imageSmWebp: purelyWhiteSmWebp,
  },
  {
    title: () => (
      <>
        Smoky <br />
        Gray
      </>
    ),
    color: "#7E7D81",
    isWhite: false,
    image: smokyGray,
    imageWebp: smokyGrayWebp,
    imageSm: smokyGraySm,
    imageSmWebp: smokyGraySmWebp,
  },
  {
    title: () => (
      <>
        Natural <br />
        Sand
      </>
    ),
    color: "#AFA393",
    isWhite: false,
    image: dustyTaupe,
    imageWebp: dustyTaupeWebp,
    imageSm: dustyTaupeSm,
    imageSmWebp: dustyTaupeSmWebp,
  },
  {
    title: () => (
      <>
        Dark <br />
        Moss
      </>
    ),
    color: "#506056",
    isWhite: false,
    image: darkMoss,
    imageWebp: darkMossWebp,
    imageSm: darkMossSm,
    imageSmWebp: darkMossSmWebp,
  },
  {
    title: () => (
      <>
        Twilight <br />
        Blue
      </>
    ),
    color: "#545765",
    isWhite: false,
    image: twilightBlue,
    imageWebp: twilightBlueWebp,
    imageSm: twilightBlueSm,
    imageSmWebp: twilightBlueSmWebp,
  },
];

/**
 * @returns {JSX.Element}
 * @constructor
 */

const BackgroundTab = () => {
  const isWebPSupported = checkWebPSupport();
  const isMobile = useIsMobile(769);

  const [isActive, setIsActive] = useState(3);
  const [intervalId, setIntervalId] = useState(null);
  const listRef = useRef(null);

  const clickHandler = (e, index) => {
    e.preventDefault();
    setIsActive(index);
    clearInterval(intervalId);
    const newIntervalId = setInterval(() => {
      setIsActive((prev) => (prev + 1) % tabData.length);
    }, 7000);
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsActive((prev) => (prev + 1) % tabData.length);
    }, 7000);
    setIntervalId(interval);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (listRef.current) {
      const activeButton = listRef.current.querySelector(
        `li:nth-child(${isActive + 1}) button`
      );
      if (activeButton) {
        const listContainer = listRef.current;
        const offset = 20;
        const activeButtonRect = activeButton.getBoundingClientRect();
        const listContainerRect = listContainer.getBoundingClientRect();
        const scrollLeft =
          listContainer.scrollLeft +
          (activeButtonRect.left - listContainerRect.left) -
          offset;
        listContainer.scrollTo({ left: scrollLeft, behavior: "smooth" });
      }
    }
  }, [isActive]);

  const activeImage = isWebPSupported
    ? tabData[isActive].imageWebp
    : tabData[isActive].image;
  const activeImageSm = isWebPSupported
    ? tabData[isActive].imageSmWebp
    : tabData[isActive].imageSm;
  return (
    <section className="background-tab">
      <div className="background-tab__card">
        <AnimatePresence mode="wait">
          <motion.div
            key={isActive}
            className="background-tab__image"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              backgroundImage: `url(${isMobile ? activeImageSm : activeImage})`,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        </AnimatePresence>
        <div className="background-tab__wrapper">
          <motion.h2
            className="background-tab__title"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            <strong>Five</strong> <br />
            colors.
          </motion.h2>
          <motion.div
            className="background-tab__scroller-holder"
            initial={{ opacity: 0 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <div className="background-tab__scroller">
              <ul className="background-tab__list" ref={listRef}>
                {tabData.map(({ isWhite, color, title }, index) => {
                  return (
                    <li key={index}>
                      <button
                        type="button"
                        className={`${isWhite ? "button-white " : ""}${
                          isActive === index ? "active" : ""
                        }`}
                        onClick={(e) => clickHandler(e, index)}
                        style={{
                          "--color": color,
                        }}
                      >
                        <span>{title()}</span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default BackgroundTab;
